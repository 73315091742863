$(document).ready(function () {
  var $serviceRequest = $('.js-service-request');
  var $categoryForm = $('.js-category-form');
  var $serviceForms = $serviceRequest.find('form').not($categoryForm);
  var $selectedItem = $serviceRequest.find('.js-selected-item');
  var $selector = $serviceRequest.find('.js-selector');

  $selectedItem.on('click', function (event, ignoreHistory) {
    event.preventDefault();

    $serviceForms.addClass('hidden');

    var hash = '';
    var selectedValue = $(this).data('hash');
    if (selectedValue) {
      hash = '#' + selectedValue;

      $(hash).removeClass('hidden');
      $categoryForm.addClass('ws-validate');
    }

    if (!hash && ignoreHistory) {
      $categoryForm.removeClass('ws-validate');
      $selector.removeClass('user-error');
    }

    if (ignoreHistory) {
      return;
    }

    var previous = {
      hash: selectedValue
    };

    window.updateHistory(hash || window.location.pathname, previous);
  });

  window.addEventListener('popstate', function (event) {
    if (!event.state) {
      return;
    }

    activeForm(event.state.hash);
  });

  function activeForm(hash) {
    var $optionForm = $serviceRequest.find('[data-hash="' + hash + '"]');
    $optionForm = $optionForm.length ? $optionForm : $selectedItem.first();

    $($optionForm).trigger('click', [true]);
  }

  function activeDefault() {
    if (!$serviceRequest.length) {
      return;
    }

    var hash = window.location.hash;

    if (!hash) {
      var previous = {
        hash: ''
      };
      window.updateHistory(window.location.pathname, previous);

      return;
    }

    hash = hash.split('#')[1];
    activeForm(hash);
  }

  activeDefault();
});
